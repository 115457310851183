<template>
  <sm-categories-lists
    :breadcrumbs="breadcrumbs"
    :categories-group-name="categoriesGroupName"
    :list-name="categoriesGroupName"
  />
</template>

<script>
// components
import SmCategoriesLists from '@/components/views/SmCategoriesLists.vue';

export default {
  name: 'AdministrationMain',

  components: {
    SmCategoriesLists,
  },

  data() {
    return {
      breadcrumbs: [{ text: 'Администрирование', route: '' }],

      categoriesGroupName: 'administration',
      categoriesGroup: [
        {
          title: 'Общие',
          list: [
            {
              name: 'Виды БД',
              route: { name: 'CustomerSettingsDatabaseTypes' },
              controllerName: 'CustomerSettingsDatabaseTypes',
            },
            {
              name: 'Шаблоны заявок',
              route: { name: 'SupportReqTemplates' },
              controllerName: 'SupportReqTemplates',
            },
            {
              name: 'Меню справки',
              route: { name: 'KrishaHelpMenu' },
              controllerName: 'KrishaHelpMenu',
            },
          ],
        },

        {
          title: 'Почта',
          list: [
            {
              name: 'Сообщения с почты',
              route: { name: 'MailBoxesMessages' },
              controllerName: 'MailBoxesMessages',
            },
          ],
        },

        {
          title: 'Сотрудники',
          list: [
            {
              name: 'Роли сотрудников',
              route: { name: 'Roles' },
              controllerName: 'Roles',
            },
            {
              name: 'Список сотрудников',
              route: { name: 'AccountEmployees' },
              controllerName: 'AccountEmployees',
            },
            {
              name: 'Подразделения',
              route: { name: 'Departments' },
              controllerName: 'Departments',
            },
            {
              name: 'Отчет по выполненным заявкам сотрудников',
              route: { name: 'EmployeeReport' },
              controllerName: 'EmployeeReport',
            },
          ],
        },

        {
          title: 'Настройки 1С',
          list: [
            {
              name: 'Версии клиента 1С',
              route: { name: 'Launcher1CVersions' },
              controllerName: 'Launcher1CVersions',
            },
            {
              name: 'Настройка платформ',
              route: { name: 'LauncherPlatforms' },
              controllerName: 'LauncherPlatforms',
            },
            {
              name: 'Конфигурации',
              route: { name: 'TypeBases1C' },
              controllerName: 'TypeBases1C',
            },
            {
              name: 'Подключения по умолчанию сетевой папки',
              route: { name: 'FolderDefaultConnections' },
              controllerName: 'FolderDefaultConnections',
            },
          ],
        },

        {
          title: 'Маршрутизация',
          list: [
            {
              name: 'Маршруты',
              route: { name: 'SupportRoutes' },
              controllerName: 'SupportRoutes',
            },
            {
              name: 'Статусы',
              route: { name: 'SupportInitiationStatuses' },
              controllerName: 'SupportInitiationStatuses',
            },
            {
              name: 'Типы заявок',
              route: { name: 'SupportGisTypes' },
              controllerName: 'SupportGisTypes',
            },
          ],
        },

        {
          title: 'Отчеты',
          list: [
            {
              name: 'Анализ заявок ОСС и ОСП по дням месяца за период',
              route: {
                name: 'StructureApplicationsTimeDayReport',
              },
              controllerName: 'StructureApplicationsTimeDayReport',
            },
            {
              name: 'Анализ заявок ОСС и ОСП по часам в дне за период',
              route: {
                name: 'StructureApplicationsTimeHoursReport',
              },
              controllerName: 'StructureApplicationsTimeHoursReport',
            },
            {
              name: 'Отчет по мониторингу ГИС ЖКХ запросы ОСЗН',
              route: { name: 'DebtRequestsReport' },
              controllerName: 'DebtRequestsReport',
            },
            {
              name: 'Сводный отчет по пробитию чеков орандж - дата',
              route: { name: 'PaymentsVsPaychecksReport' },
              controllerName: 'PaymentsVsPaychecksReport',
            },
            {
              name: 'Отчет по ошибкам формирования чеков',
              route: { name: 'PaychecksErrorsReport' },
              controllerName: 'PaychecksErrorsReport',
            },
            {
              name: 'Сводный паспорт выгрузки в ГИС ЖКХ',
              route: { name: 'GisExportCommonStatisticsReport' },
              controllerName: 'GisExportCommonStatisticsReport',
            },
          ],
        },
      ],
    };
  },
};
</script>
